<neo-card>
   
    <img
    neo-card-image
    src="#"

  />
   <neo-card-header>  
      <neo-card-title>Anthony Naples</neo-card-title>
      <neo-card-subtitle>Chameleon <br> Ans</neo-card-subtitle>
    </neo-card-header>
    <neo-card-content>
      <span>420 RON</span>
      <SPAN>*in stock</SPAN>
      <mat-icon aria-hidden="false" aria-label="Example home icon">favorite</mat-icon>
      </neo-card-content>
    <neo-card-footer>
        <neo-card-actions align="end">
          <button neo-button color="primary">Add to Basket</button>
        </neo-card-actions>
      </neo-card-footer>
  </neo-card>