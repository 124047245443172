import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, ItemComponent, SkuComponent } from './app.component';
import { NeoButtonModule }  from '@neomorphism/ng-neomorphism/neo-button';     
import { NeoFormFieldModule }  from '@neomorphism/ng-neomorphism/neo-form-field'  
import { NeoInputModule }  from '@neomorphism/ng-neomorphism/neo-input'   
import {MatIconModule} from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { NeoCardModule }  from '@neomorphism/ng-neomorphism/neo-card';   
import { MatButtonModule } from '@angular/material/button';
import { NeoRippleModule, RIPPLE_TYPE, RIPPLE_TYPES } from './neo-ripple/neo-ripple.module';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';



@NgModule({
  declarations: [
    AppComponent,
    ItemComponent,
    SkuComponent
  ],
  imports: [
    NeoRippleModule,
    BrowserModule,
    AppRoutingModule,
    NeoButtonModule,
    NeoFormFieldModule,
    NeoInputModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    NeoCardModule
    
  ],
  providers: [
    { provide: RIPPLE_TYPE, useValue: RIPPLE_TYPES.NEO },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: { animation: { enterDuration: 1000 } },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
