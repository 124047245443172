import { style } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'spa';
  items = [{title: 'Ans', artist: 'Anthony Naples', album: 'Chamelon'},
  {title: 'A Strangely Isolated Place', artist: 'Strie / Scanner', album: 'Struktura Revisited'},
{
  artist: 'D\'Arcangelo', album: 'Arium (EP)', title: 'A Colorful Storm'
}]
}


@Component({
  selector: 'vinyl-item',
  template: '<div><img src="#"/> <div class="details"><span class="artist">{{item.artist}}</span> <p>{{item.album}}</p> <p> {{item.title}}</p></div></div>',
  styles: [':host {line-height: 0.6;} img { width: 56px; height: 56px; padding:4px;} .details {display: inline-block; padding-left: 20px; } .artist { color: #faa61c;  font-family: Akrobat; }']
})
export class ItemComponent {
  @Input ('product') item: any
  constructor() {

  }
}

@Component({
  selector: 'sku-vinyl',
  templateUrl: './sku.component.html',
  styles: [':host {display: inline-block; padding:10px;}']
})
export class SkuComponent {

}